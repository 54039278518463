<template>
  <Flicking :options="options" :plugins="plugins" class="swiper" ref="reaa">
    <div
      class="item"
      v-for="teacher in teacherList"
      :key="teacher.id"
      @click="ads"
    >
      <div class="item-t" :data-tid="teacher.id">
        <div class="teacher-img">
          <img
            :src="'http://tot.xlteacher.cn' + teacher.pic"
            :data-tid="teacher.id"
          />
        </div>
        <p class="t-name" :data-tid="teacher.id">{{ teacher.name }}</p>
        <p class="t-title" :data-tid="teacher.id">
          职称：{{ teacher.position }}
        </p>
      </div>
    </div>
    <span slot="viewport" class="flicking-arrow-prev"></span>
    <span slot="viewport" class="flicking-arrow-next"></span>
  </Flicking>
  <!-- <swiper
    @click="ads"
    ref="teacher-swiper"
    :options="swiperOptions"
    class="swiper"
  >
    <swiper-slide class="item" v-for="teacher in teacherList" :key="teacher.id">
      <div class="item-t" :data-tid="teacher.id">
        <div class="teacher-img">
          <img
            :src="'http://tot.xlteacher.cn' + teacher.pic"
            :data-tid="teacher.id"
          />
        </div>
        <p class="t-name" :data-tid="teacher.id">{{ teacher.name }}</p>
        <p class="t-title" :data-tid="teacher.id">
          职称：{{ teacher.position }}
        </p>
      </div>
    </swiper-slide>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper> -->
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import { Arrow } from "@egjs/flicking-plugins";
import "../assets/css/flicking.min.css";
import "../assets/css/arrow.min.css";

export default {
  name: "Teacher",
  props: {
    teacherList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      plugins: [new Arrow()],
      options: {
        panelsPerView: 4,
        align: "prev",
        circular: true,
      },
    };
  },

  components: { Flicking },
  mounted() {},
  computed: {},
  methods: {
    ads(s) {
      if (!s.target.dataset.tid) return;
      this.$router.push(`/tdetail?tid=${s.target.dataset.tid}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  min-height: 300px;
  position: unset;
  margin-top: 20px;
  .item {
    margin-top: 10px;
    pointer-events: all;
    .item-t {
      background-color: #fff;
      max-width: 192px;
      height: 280px;
      margin: 0 auto;
      text-align: center;
      border-radius: 10px;
      transition: all ease 0.4s;
      user-select: none;
      cursor: pointer;
      padding-top: 1px;
      &:hover {
        box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
      }

      .teacher-img {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        margin: 29px auto 0;
        overflow: hidden;
        img {
          width: 100%;
          // max-height: 100%;
        }
      }
      p {
        margin: 0;
        max-width: 140px;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .t-name {
        font-size: 20px;
        line-height: 52px;
      }
      .t-title {
        font-size: 14px;
      }
      .t-goodat {
        font-size: 12px;
      }
    }
  }
  .flicking-arrow-prev {
    width: 25px;
    height: 50px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    cursor: pointer;
    &::after {
      width: 20px;
      height: 5px;
      left: 5px;
      background-color: #fff;
    }
    &::before {
      width: 20px;
      left: 5px;
      height: 5px;

      background-color: #fff;
    }
  }
  .flicking-arrow-next {
    width: 25px;
    height: 50px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;

    &::after {
      width: 20px;
      height: 5px;
      background-color: #fff;
      left: 0;
    }
    &::before {
      width: 20px;
      height: 5px;
      left: 0;

      background-color: #fff;
    }
  }
}
</style>
<style lang="scss">
.swiper-wrapper {
  position: unset;
}
.intro-wrapper {
  .swiper-button-next {
    right: -10px;
    &::after {
      color: #fff;
      padding: 10px 5px;
      font-size: 30px;
      background-color: rgba($color: #000000, $alpha: 0.3);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  .swiper-button-prev {
    left: -10px;
    &::after {
      color: #fff;
      padding: 10px 5px;
      font-size: 30px;
      background-color: rgba($color: #000000, $alpha: 0.3);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
}
</style>

import { render, staticRenderFns } from "./Teacher.vue?vue&type=template&id=002d0795&scoped=true&"
import script from "./Teacher.vue?vue&type=script&lang=js&"
export * from "./Teacher.vue?vue&type=script&lang=js&"
import style0 from "./Teacher.vue?vue&type=style&index=0&id=002d0795&lang=scss&scoped=true&"
import style1 from "./Teacher.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002d0795",
  null
  
)

export default component.exports